.footer {
  padding-top: 0;
  margin-top: 2rem;
  font-size: 1rem;
  background: var(--hn-gray-100);

  @include media-breakpoint-up(lg) {
    justify-content: space-between;
  }

  &__main {
    >.container {
      justify-content: space-between;
      gap: 5rem 3rem;
    }
  }

  &__logo {
    a {
      display: block;
      max-width: 220px;

      .cls-1 {
        fill: var(--hn-body-color);
      }

      .cls-2,
      .cls-3 {
        fill: $primary;
      }
    }
  }


  &__menu {
    width: 100%;
    gap: 2rem;
    margin-right: 0;
    flex-wrap: wrap;

    display: grid;
    flex: 1 0 0px;
    gap: 32px;
    grid-auto-rows: min-content;
    grid-template-columns: repeat(1, minmax(1px, 1fr));
    grid-template-rows: repeat(1, min-content);
    height: min-content;
    justify-content: flex-start;

    @include media-breakpoint-up(md) {
      justify-content: center;
      grid-template-columns: repeat(3, minmax(1px, 1fr));
    }

    >div {
      flex-basis: unset;
      width: 100%;
      margin: 0;
      padding: 0;

      @include media-breakpoint-up(md) {
        flex-basis: fit-content;
      }
    }

    @include media-breakpoint-up(xl) {
      width: auto;
      gap: 2rem;
    }

    .nav-link {
      padding: .25rem 0;
    }
  }

  &__title {
    --hn-headings-font-weight: bold;
    --hn-headings-font-family: var(--hn-body-font-family);
    font-size: 0.875rem;
    font-weight: var(--hn-headings-font-weight);
    margin-bottom: 1rem;
    display: block;
  }

  &__contact {
    width: 100%;

    @include media-breakpoint-up(md) {
      width: 50%;
      padding-right: 0;
    }

    @include media-breakpoint-up(xl) {
      width: calc(30% - 5rem);
    }
  }

  &__social {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    width: min-content;

    .social {
      &__icon {
        border: none;
        padding: 0;
        height: 16px;
        width: 16px;
      }
    }
  }

  &__sub {
    font-size: 0.75rem;
    padding-top: 2rem;
    padding-bottom: 2rem;

    >.container {
      border-top: 1px solid rgb(0, 0, 0, 0.1);
      padding-top: 2rem;
    }
  }
}