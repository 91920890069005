:root {
  --hn-standard-transition: all 250ms cubic-bezier(0.53, 0, 0, 1);
  --hn-form-control-bg: #{$white};
  --hn-form-control-border: none;

  --step--1: clamp(0.8rem, calc(0.84rem + -0.03vw), 0.83rem);
  --step-0: clamp(1rem, calc(1rem + 0vw), 1rem);
  --step-1: clamp(1.2rem, calc(1.19rem + 0.05vw), 1.25rem);
  --step-2: clamp(1.44rem, calc(1.42rem + 0.12vw), 1.56rem);
  --step-3: clamp(1.73rem, calc(1.68rem + 0.23vw), 1.95rem);
  --step-4: clamp(2.07rem, calc(2rem + 0.37vw), 2.44rem);
  --step-5: clamp(2.49rem, calc(2.38rem + 0.56vw), 3.05rem);

  --hn-border-radius-custom: 1rem;
  --hn-border-radius-sm: 12px;
  --hn-global-spacing-8: 0.5rem;
  --hn-global-spacing-16: 1rem;
  --hn-global-spacing-24: 1.5rem;
  --hn-global-spacing-32: 2rem;
  --hn-global-spacing-48: 3rem;
  --hn-global-spacing-64: 4rem;
  --hn-global-spacing-80: 5rem;
  --hn-global-spacing-128: 8rem;

  --hn-primary-bg: #f5f2ef;
  --hn-gutter-x: 60px;
  --hn-custom-green: #76b62e;
  --hn-gray-100: #f1f7f9;
}

html,
body {
  overflow-x: hidden;
}

@mixin make-max-widths-container-width($max-widths: $container-max-widths,
  $breakpoints: $grid-breakpoints) {
  @include make-container;

  @each $breakpoint, $container-max-width in $max-widths {
    @include media-breakpoint-up($breakpoint, $breakpoints) {
      max-width: $container-max-width;
    }
  }
}

.btn-default {
  --hn-btn-bg: var(--hn-white);
}

@mixin custom-padding($what) {
  padding-#{$what}: calc(1.5rem + 1.5vw);
}

@mixin col-offset($what, $way, $col) {
  #{$what}-#{$way}: calc((100% / 12) * $col);
}

.btn {
  display: inline-flex;
  flex-direction: row;
  align-self: flex-start;
  align-items: center;
  border-radius: calc(var(--hn-border-radius) * 0.5);
  padding: 0.675rem 1rem;

  &-lg {
    font-size: 1.125rem;
    height: 50px;
  }

  &-warning,
  &-success {
    --hn-btn-color: var(--hn-white);
  }

  &-primary {
    --hn-btn-color: var(--hn-primary-bg);
  }

  &-white {
    --hn-btn-color: var(--hn-primary);
    --hn-btn-bg: var(--hn-white);
    --hn-btn-hover-bg: var(--hn-gray-200);
    --hn-btn-hover-color: var(--hn-primary);
  }

  &-link__row {
    display: flex;
    flex-direction: row;
    gap: var(--hn-global-spacing-48);
    width: 100%;
    align-items: flex-start;
  }

  &-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    gap: 1rem;
    margin-top: 1rem;

    &.align-center {
      justify-content: center;
    }
  }

  &-mt {
    margin-top: 2rem;
  }
}

.section {
  .text {

    .btn-outlne-primary,
    .btn-outline-secondary {
      margin-top: 2.5rem;
      margin-right: 1.5rem;
    }
  }
}

.pagination {
  --hn-pagination-bg: var(--hn-white);
  --hn-pagination-color: var(--hn-body-color);
}

.specials {
  .btn.btn-outline-primary {
    --hn-btn-color: var(--hn-white);
    --hn-btn-bg: var(--hn-primary);
    --hn-btn-hover-bg: var(--hn-body-color);
    --hn-btn-border-color: var(--hn-primary);
    --hn-btn-hover-border-color: var(--hn-primary);
    --hn-btn-hover-color: var(--hn-white);
    padding: 0.5rem 1rem;
    font-size: 14px;
  }
}

.bg-gray-100 {
  background-color: var(--hn-gray-100);

  &.text-center {
    padding: 2rem;
    border-radius: var(--hn-border-radius);
  }
}

.section:not(.swiper),
.section-spacer {
  padding: calc(1.5rem + 1.5vw) 0;
  padding-top: unset;
}

.ml-auto {
  margin-left: auto;
}

.section:not(.main) {
  overflow: visible;
}

.star-row {
  svg {
    color: #fdd663;
  }
}

.btn-row,
.star-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.25rem;

  @include media-breakpoint-up(lg) {
    flex-wrap: nowrap;
  }

  .btn {
    flex-grow: 1;
    justify-content: center;
  }
}

.grid-row {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  gap: 1rem;

  &.w-4 {
    @include media-breakpoint-up(lg) {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  &.w-3 {
    @include media-breakpoint-up(lg) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &__item {
    background: var(--hn-white);
    display: flex;
    flex-direction: column;
    border-radius: 0.5rem;
    overflow: hidden;
    transition: all 400ms cubic-bezier(1, 0.08, 0.18, 0.74);
    width: 100%;
    counter-increment: num-sections;
    position: relative;

    @include media-breakpoint-up(lg) {
      flex: 1;
    }

    &.bg-gray-100 {
      background-color: var(--hn-gray-100);
    }
  }

  &__number:before {
    position: absolute;
    left: 1rem;
    top: 1rem;
    content: counter(num-sections);
    z-index: 9;
    color: white;
    background-color: rgba($body-color, 50%);
    backdrop-filter: blur(4px);
    border-radius: 0.5rem;
    font-size: 1.5rem;
    font-weight: bold;
    width: 3rem;
    height: 3rem;
    line-height: 2;
    text-align: center;
  }

  &__photo {
    position: relative;

    img {
      aspect-ratio: 2/1;
      object-fit: cover;
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    padding: clamp(1rem, calc(0.5rem + 1vw), 2rem);
    height: 100%;

    .btn.btn-block {
      margin-top: auto;
      width: 100%;
      display: block;
      margin-bottom: unset;
    }
  }

  &.horizontal {
    display: grid;
    row-gap: 1rem;

    @include media-breakpoint-up(lg) {
      grid-auto-rows: 1fr;
    }

    .grid-row__item {
      background: var(--hn-secondary);
      justify-content: center;
    }

    .grid-row__content {
      padding: 2rem;
      display: flex;

      span.icons {
        width: 20%;
        display: flex;
        font-size: 10vw;

        @include media-breakpoint-up(md) {
          align-items: center;
          font-size: 4vw;
          justify-content: space-evenly;
        }

        > :first-child {
          color: var(--hn-white);
        }

        > :nth-child(2) {
          svg {
            fill: var(--hn-primary);
          }

          @include media-breakpoint-down(md) {
            display: none;
          }
        }
      }

      .text {
        width: 80%;
        flex-direction: column;

        * {
          color: var(--hn-white);
        }
      }
    }
  }
}

.magazine-row {
  display: flex;
  align-items: center;
  position: relative;
  flex-direction: column;
  width: 100%;

  @include media-breakpoint-up(md) {
    flex-direction: row;
  }

  >* {
    flex: 1;
  }

  &__img {
    position: relative;
    pointer-events: none;

    // display: none;
    .arrow {
      flex-shrink: 0;
      margin-top: -5px;

      @media (min-width: 320px) {
        margin-top: -30px;
      }

      @media (min-width: 420px) {
        margin-top: -60px;
      }

      @media (min-width: 770px) {
        margin-top: 0;
      }
    }

    @media (max-width: 769px) {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;

      .arrow {
        transform: rotate(90deg);

        img {
          height: 100px;
        }
      }
    }

    @include media-breakpoint-up(md) {
      // display: block;

      >.image {
        position: absolute;
        transform: translateY(-50%);

        img {
          width: 100%;
          height: 100%;
          aspect-ratio: 4 / 4;
        }
      }

      >.arrow {
        display: block;
        position: absolute;
        transform: translate(25%, -65%);
        width: 50%;
        right: 0;

        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }

  &__text {
    text-align: center;
    padding: 2rem;

    @include media-breakpoint-up(lg) {
      padding: 7.5rem 0;
    }

    h2,
    p {
      color: var(--hn-white);
    }
  }
}

.housingnet-row {
  display: flex;
  position: relative;
  z-index: 9;
  flex-direction: column;

  @include media-breakpoint-up(lg) {
    flex-direction: row;
  }

  &__text {
    height: min-content;
    background: var(--hn-custom-green);
    color: var(--hn-white);
    padding: 15px;
    position: relative;
    border-radius: 2rem;
    text-align: center;
    flex: 1 60%;
    order: 0;

    @include media-breakpoint-up(lg) {
      order: 2;
    }

    @include media-breakpoint-up(md) {
      top: 2rem;
      padding: 2rem;
    }

    @include media-breakpoint-up(xl) {
      flex: 1 70%;
    }

    p.lead,
    p,
    h2,
    h3 {
      color: var(--hn-white);
    }
  }

  &__character {
    flex: 1 30%;
    display: flex;
    position: relative;
    order: 1;

    @include media-breakpoint-up(lg) {
      flex: 1 20%;
    }

    @include media-breakpoint-up(xxl) {
      justify-content: center;
    }

    &:before {
      content: "";
      position: absolute;
      top: -3.5rem;
      right: 4rem;
      width: 0;
      height: 0;
      border: 4em solid transparent;
      border-radius: 50%;
      box-shadow: 2em 0 0 0 var(--hn-custom-green);

      @include media-breakpoint-up(md) {
        right: -1rem;
        top: 5%;
        transform: rotate(60deg);
      }
    }
  }

  p.lead {
    color: var(--hn-white);
  }
}

.custom-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--hn-global-spacing-16);

  @include media-breakpoint-up(lg) {
    grid-auto-rows: 1fr;
  }

  &.w-3 {
    grid-template-columns: repeat(3, 1fr);
  }

  &.services {
    @include media-breakpoint-up(lg) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__item {
    border-radius: 1rem;
    background-color: var(--hn-secondary);
    display: flex;
    flex-direction: column;
    padding: 1rem;

    @include media-breakpoint-up(lg) {
      padding: 2.5rem;
    }

    a {
      margin-top: auto;
    }

    h3,
    h2,
    p {
      color: var(--hn-white);
      word-break: break-word;
    }
  }
}

.form {
  background: var(--hn-secondary);
  border-radius: 1rem;
  padding: var(--hn-global-spacing-32) 0;
  width: 100%;
}

.form-nexxtmove {
  padding: var(--hn-global-spacing-32) 0;
  width: 100%;
}

.custom-contact {
  border-radius: 1rem;
  padding: 1rem;
  margin: unset;

  background: var(--hn-secondary);

  @include media-breakpoint-up(lg) {
    padding: 2rem;
  }

  .company__details,
  .title,
  a {
    color: white;
    text-decoration: none;
  }
}

.card_row {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;

  @include media-breakpoint-up(lg) {
    gap: 2rem;
    flex-flow: row nowrap;
  }

  &__item {
    flex: 1;
    position: relative;
    overflow: hidden;
    border-radius: 0.5rem;
    background: var(--hn-gray-100);
    transition: all 400ms cubic-bezier(1, 0.08, 0.18, 0.74);
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
    }

    &:hover {
      transform: translateY(-4px);
    }

    &.blue {
      background: var(--hn-primary);

      *:not(a) {
        color: var(--hn-white);
      }
    }
  }

  &__image {
    position: relative;
    width: 100%;
    height: 33.33333vw;
    border-radius: 0 0 100% 100%;
    overflow: hidden;
    right: 0;
    top: 0;
    order: 0;

    @include media-breakpoint-up(lg) {
      height: 50%;
      position: absolute;
      order: 1;
      width: 45%;
      height: 100%;
      border-radius: 0 0 0 200%;
    }

    >img {
      position: absolute;
      bottom: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  &__text {
    padding: 1rem;
    order: 1;
    display: flex;
    flex-direction: column;

    .btn {
      margin-top: auto;
    }

    @include media-breakpoint-up(lg) {
      padding: 2rem;
      order: 0;
      max-width: 55%;
    }
  }
}

.video-wrapper {
  order: 0;
  border-radius: 0.5rem;
  position: relative;
  overflow: hidden;
  width: 100%;
  position: relative;

  @include media-breakpoint-up(lg) {
    @include make-col(6);
  }

  video {
    width: 100%;
    height: 100%;
  }
}

ul.check-list {
  list-style: none;
  position: relative;
  margin-top: 1rem;
  background: white;
  padding: 2rem;
  padding-left: 4rem;
  border-radius: 2rem;
  height: 100%;

  li {
    margin: 0.5rem 0;

    &:before {
      background: #76b62e;
      border-radius: 1rem;
      font-size: 0.8rem;
      position: absolute;
      left: 1.5rem;
      color: rgb(255, 255, 255);
      width: 1.5rem;
      height: 1.5rem;
      content: "✓";
      text-align: center;
      line-height: 2;
    }
  }
}

// lege bedanktpagina's

.page89,
.page88,
.page90 {
  *:not(main, .section.main, .section.main *) {
    display: none;
  }

  .section {
    &.main {
      height: 100vh;
      padding: unset;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}