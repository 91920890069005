.home,
body,
.page,
.company,
.objects,
.employees {
  .header {
    background: var(--hn-body-bg);
    height: unset;
    height: auto;
    align-items: center;
    max-width: 100vw;
    justify-content: flex-end;
    padding: 2rem calc(var(--hn-gutter-x)* 0.5) 1rem;

    @include media-breakpoint-up(lg) {
      justify-content: space-between;
      padding: 2rem calc(var(--hn-gutter-x)* 0.5) 1rem;
    }

    &:before {
      content: "";
      background: var(--hn-secondary);
      width: 100%;
      height: 1rem;
      position: absolute;
      top: 0;
      left: 0;
    }

    .header__topmenu {
      justify-content: flex-end;

      .nav {
        >.nav-item {
          position: relative;
          cursor: pointer;
          display: flex;

          &.level-1 {
            >.nav-link.active {
              color: var(--hn-secondary)
            }
          }

          .nav-link {
            --hn-nav-link-color: var(--hn-body-color);
            border-radius: 0;
            padding: .5rem 1rem;
            font-weight: var(--hn-body-font-weight);
            font-family: var(--hn-body-font-family);
            text-transform: uppercase;
          }
        }

        .dropdown-menu {
          border-radius: .5rem;
          --hn-dropdown-bg: var(--hn-white);
          border: 1px solid var(--hn-gray-200);
          width: max-content;
        }

        .nav-item.level-2 {
          >.nav-link {
            font-family: var(--hn-body-font-family);
            text-transform: unset;
            padding: 0.5rem 1rem;
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            color: var(--hn-body-color);
          }
        }
      }
    }

    &__toggle {
      border: unset;
      order: 9;
      border-radius: .5rem;
      color: var(--hn-white);
      background: var(--hn-primary);

      @include media-breakpoint-up(lg) {
        display: none;
      }
    }

    &--top {
      position: relative;

      @include media-breakpoint-up(lg) {
        padding-top: 2rem;
        padding-bottom: 1rem;
      }

      .header__topmenu {
        .nav {
          >.nav-item {
            .nav-link {
              --hn-nav-link-color: var(--hn-body-color);
              color: var(--hn-nav-link-color);
              font-size: 14px;
            }
          }
        }
      }

      .header {

        &__toggle {
          border: unset;
          order: 9;
          background: var(--hn-primary);
          color: var(--hn-white);
          margin: 0;

          @include media-breakpoint-up(lg) {
            display: none;
            margin: 0 1rem;
          }
        }
      }

      .header__cta {
        .btn-cta {
          border-radius: .5rem;
        }
      }
    }

    &--not-top {
      background-color: var(--hn-white);

      &:after {
        display: none;
      }

      &.header--bottom {
        background-color: var(--hn-white);
      }

      .header {
        &__topmenu {
          .nav {
            >.nav-item {
              .nav-link {
                color: var(--hn-nav-link-color);
                font-size: 14px;
              }
            }
          }
        }

        &__cta {
          .btn-cta {
            font-size: 14px;
            border: none;
            color: var(--hn-white);
            background: var(--hn-primary);
            padding: .5rem .75rem;
            border-radius: .5rem;

          }
        }
      }
    }

    &__cta {
      order: 9;

      .btn-cta {
        font-size: 14px;
        border: none;
        color: var(--hn-white);
        background: var(--hn-primary);
        padding: .5rem .75rem;

        .icon,
        svg {
          @include media-breakpoint-up(lg) {
            display: none;

          }
        }
      }

      .dropdown {
        &-menu {
          border: 1px solid #e0e0e0;
          border-radius: 0;
        }

        &-item {
          color: var(--hn-body-color);

          svg {
            color: var(--hn-body-color);

            &.fa-chevron-right {
              display: none;
            }
          }
        }
      }
    }

    &__topbar {
      background: var(--hn-white);
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;
      position: relative;
      padding: 1rem;

      @include media-breakpoint-down(lg) {
        padding: 1rem 4rem;
      }

      a {
        color: var(--hn-body-color);
        text-decoration: none;
        padding: 0.25rem 0.75rem;
        border-radius: 0;

        &:hover {
          background: var(--hn-primary-bg);
          color: var(--hn-primary-bg)
        }
      }
    }

    &__mainmenu {
      .nav-link {
        --hn-nav-link-color: var(--hn-nav-link-color);
      }


    }

    &__langpicker {
      order: 6;
    }
  }
}

.header__logo {
  justify-content: flex-start;

  .st0 {
    fill: var(--hn-primary);
  }

  .st1 {
    fill: var(--hn-body-color)
  }
}

.textpage {

  .header,
  .header--top {
    background-color: var(--hn-white);
  }
}