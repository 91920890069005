.home {
  .mainSlider {
    @include make-container;
    overflow: visible;
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 1.5rem;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
    background: var(--hn-body-bg);
    padding-bottom: unset;
    margin-bottom: calc(1.5rem + 1.5vw);
    height: auto;

    @include media-breakpoint-up(lg) {
      height: 75vh;
      flex-wrap: nowrap;
    }

    h1,
    h2,
    h3,
    p {
      --hn-headings-color: var(--hn-white);
    }

    .swiper {
      &-wrapper {
        position: absolute;
        border-radius: var(--hn-border-radius);
        overflow: hidden;
        left: 30px;
        right: 30px;
        width: auto;
        height: 100%;

        .swiper-bg {
          &:before {
            display: none;
          }

          &:after {
            height: 0;
          }

          img {
            object-position: 0 75%;
            height: 100%;
            object-fit: cover;
            object-position: top;
          }
        }
      }

      &-fixedcontent {
        justify-content: flex-start;
        position: relative;
        padding: calc(1rem + 1vw);
        width: 100%;

        @include media-breakpoint-up(lg) {
          padding: 2rem 60px;
        }

        >.container {
          padding: unset;
          margin: unset;
          height: 100%;
        }

        .swiper-content {
          padding: unset;
          margin: auto;
          display: flex;
          flex-direction: column;
          height: 100%;
          max-width: 100%;
          position: relative;

          @include media-breakpoint-up(lg) {
            margin: unset;
          }

          .text {
            display: flex;
            flex-grow: 1;
            flex-direction: column;
            width: 100%;
            max-width: unset;
            align-items: center;
            justify-content: center;

            .btn-row {
              margin-top: unset;
              margin: auto;
              justify-content: center;
              align-items: center;
              flex-wrap: wrap;
              gap: 1rem;

              @include media-breakpoint-up(lg) {
                gap: 2rem;
              }

              .btn-custom.white {
                font-size: 1.25rem;
                color: var(--hn-white);
                border: 2px solid var(--hn-white);
                border-radius: 1rem;
                transition: 250ms ease;

                @include media-breakpoint-up(lg) {
                  font-size: 2rem;
                }

                &:hover {
                  background: var(--hn-white);
                  color: var(--hn-body-color);
                }
              }

              @include media-breakpoint-down(lg) {
                flex-direction: column;

                .btn {
                  width: 100%;
                }

              }
            }
          }
        }
      }

      &-logo-row {
        position: relative;
        display: flex;
        flex-direction: row;
        top: 0;
        width: 100%;
        justify-content: space-between;
        flex-wrap: wrap;
        row-gap: 2rem;
        margin-bottom: 2rem;

        @include media-breakpoint-up(lg) {
          position: absolute;
          flex-wrap: nowrap;
        }

        >img {
          max-width: 18.75rem;
          max-height: 7rem;
          margin: auto;
          width: 100%;

          @include media-breakpoint-up(lg) {
            max-width: 20rem;
            margin: unset;
          }
        }
      }
    }
  }


  main {
    .section {

      &.services,
      &.soldandrented {
        display: none;
      }

      &.main {
        border-radius: var(--hn-border-radius);

        .text {
          margin: auto;
          text-align: center;
          justify-content: center;


          >* {
            width: 100%;
          }

          @include media-breakpoint-up(lg) {
            @include make-col(8);
          }

        }
      }

      &.textblock1 {
        .text {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          background: var(--hn-primary);
          border-radius: 1rem;
          padding: 1rem;

          @include media-breakpoint-up(lg) {
            flex-wrap: nowrap;
          }

          h3 {
            @include font-size(2rem);
            align-self: center;
            order: 2;
            text-align: center;
            color: var(--hn-white);
            margin: 1rem;

            @include media-breakpoint-up(lg) {
              margin: calc(2rem + 4vw);
              text-align: left;
            }
          }


        }

      }

      &.textblock3 {
        padding-top: unset;
      }

      &.textblock4 {
        background: var(--hn-gray-100);
        @include custom-padding(top)
      }

      &.textblock5 {
        background: var(--hn-secondary);

        @include custom-padding(top);

        .text {
          width: 100%;
        }
      }

      &.textblock6 {
        @include custom-padding(top)
      }

      &.textblock8 {

        .text {
          text-align: center;
        }

      }
    }
  }

}

.play-button-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
  pointer-events: none;

  @include media-breakpoint-down(lg) {
    display: none;
  }

  &:hover {
    #circle-play-b {
      background: color-mix(in srgb, currentColor, white 10%);
    }
  }

  #circle-play-b {
    pointer-events: auto;
    width: 4rem;
    height: 4rem;
    position: absolute;
    background: #F40100;
    color: #F40100;
    border-radius: 50%;
    transition: all 400ms cubic-bezier(1, 0.08, 0.18, 0.74);
    top: 50%;
    transform: translateY(-50%);

    &:after {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 0 10px 15px;
      border-color: transparent transparent transparent var(--hn-white);
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      margin-left: 2px;
      transition: all 400ms cubic-bezier(1, 0.08, 0.18, 0.74);
    }
  }
}

.good-to-know {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  background: var(--hn-gray-100);
  border-radius: 1rem;
  padding: 2rem 3rem;

  @include media-breakpoint-up(lg) {
    @include make-col(9);
    margin: auto;
  }

  &--title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: auto;
    gap: 2rem;
    flex-wrap: wrap;

    @include media-breakpoint-up(lg) {
      width: 50%;
      flex-wrap: nowrap;
    }

    img {
      max-width: 4rem;
      aspect-ratio: 1;
    }
  }

  &--text {
    text-align: center;
  }
}