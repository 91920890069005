.home {
  main {
    display: flex;
    flex-direction: column;
  }

  .section {
    &.slider {
      order: 0;
    }

    &.main {
      order: 1;
    }


    &.textblock1 {
      order: 2;
    }


    &.textblock2 {
      order: 3;
    }


    &.specials {
      order: 4;
    }

    &.textblock3 {
      order: 5
    }

    &.textblock4 {
      order: 6;
    }

    &.textblock5 {
      order: 6;
    }

    &.textblock6 {
      order: 7;
    }

    &.textblock7 {
      order: 8;
    }

    &.textblock8 {
      order: 9;
    }

    &.branchelogos {
      order: 95;
    }

    &.recentlyviewed {
      order: 97;
    }

    &.footer {
      order: 99;
    }
  }
}

.employees {
  main {
    display: flex;
    flex-direction: column;
  }

  .section {
    &.pageheader {
      order: 0;
    }

    &.main {
      order: 1;
    }

    &.textblock1 {
      order: 2
    }

    &.textblock2 {
      order: 3
    }

    &.textblock3 {
      order: 4
    }

    &.textblock4 {
      order: 5
    }

    &.employees {
      order: 6;
    }

    &.textblock5 {
      order: 7;
    }

    &.textblock6 {
      order: 8;


    }

    &.branchelogos {
      order: 95;
    }

    &.footer {
      order: 99;
    }
  }
}