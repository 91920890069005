.specialsSwiper,
.recentlyviewed {
  > .container {
    padding-left: unset;
  }

  .object {
    --hn-object-border-radius: 0.5rem;
    box-shadow: unset;
    background: unset;
    height: auto;

    &__image {
      aspect-ratio: 1/1;

      &:before {
        display: none;
      }
    }

    &__header {
      width: 100%;
      height: 100%;
      align-items: flex-start;
      gap: 1rem;
    }

    &__data {
      width: 100%;
      bottom: 0;
      min-height: unset;
      position: relative;
      z-index: 9;
      background: var(--hn-gray-100);
      padding: 2rem;
      overflow: hidden;

      .title,
      .sub,
      .price,
      .price.pricesquaremeter,
      .gwe {
        color: var(--hn-body-color);
        font-size: 0.9rem;
      }

      .gwe {
        display: none;
      }

      .title {
        margin: 0.125rem 0;
        font-size: 1.125rem;
        font-family: var(--hn-headings-font-family);
        color: var(--hn-body-color);
        flex-grow: 1;
      }

      .sub,
      .price {
        color: var(--hn-body-color);
        text-transform: uppercase;
        line-height: 1;
      }
    }

    &__icon {
      display: none;
    }

    &__status {
      border-radius: unset;
      text-transform: uppercase;
      font-size: 0.75rem;
      border-radius: 0.375rem;
      padding: 0.5rem 0.75rem;
      line-height: 1;
      --hn-object-status-default-bg: var(--hn-primary);
      --hn-object-status-default-color: var(--hn-white);
      --hn-object-status-new-bg: var(--hn-primary);
      --hn-object-status-new-color: var(--hn-white);
      --hn-object-status-rentedsold-bg: var(--hn-primary);
      --hn-object-status-rentedsold-color: var(--hn-white);
      --hn-object-status-conditions-bg: var(--hn-primary);
      --hn-object-status-conditions-color: var(--hn-white);
      --hn-object-status-pricechanged-bg: var(--hn-primary);
      --hn-object-status-pricechanged-color: var(--hn-white);
      --hn-object-status-topper-bg: var(--hn-primary);
      --hn-object-status-topper-color: var(--hn-white);
      --hn-object-status-openhouse-bg: var(--hn-primary);
      --hn-object-status-openhouse-color: var(--hn-white);
    }
  }
}

.status__pill {
  background: var(--hn-white);
  color: var(--hn-body-color);
  border-radius: 8rem;
  margin-right: 2px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  padding: 0.25rem 0.75rem;
  font-size: 12px;
  margin-bottom: 1rem;
  width: max-content;
}

.searchresults {
  background-color: var(--hn-body-bg);

  .pageheader {
    padding-top: 2rem;
    padding-bottom: unset;
    background: unset;
  }

  .realestate {
    padding-top: unset;
  }

  .object_list {
    .object {
      --hn-object-bg: var(--hn-white);

      &__promo {
        background-color: var(--hn-secondary);
        color: var(--hn-white);

        &-title,
        &-subtitle {
          color: var(--hn-white);
        }

        .btn {
          margin: 0 auto;
        }
      }
    }
  }

  &-header {
    background-color: unset;
  }
}

.searchdetail {
  .objectheader {
    padding-top: 2rem;
  }

  .objectcontact__cta {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }

  .objectcontact__data {
    .objectcontact__title {
      display: none;
    }
  }

  .objectcontact__department {
    img {
      object-fit: contain;
    }
  }

  .objectcontact__image {
    aspect-ratio: unset;

    @include media-breakpoint-up(lg) {
      aspect-ratio: unset;
    }
  }

  .btn {
    align-self: unset;
    text-align: center;
    gap: 0.5rem;
  }

  .bookavisit {
    display: none;
  }
}
