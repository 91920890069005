//@import "webfont/font";
@import "../../THEMES/HW044/scss/required-header";

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  xxxl: 1720px,
);
$container-max-widths: (
  sm: 100%,
  md: 100%,
  lg: 100%,
  xl: 1140px,
  xxl: 1320px,
  xxxl: 1600px,
);

$default-color-scheme: light;
$body-bg: #fff;
$body-color: #164469;
$font-family-base: "Roboto", "Roboto-Fallback", sans-serif;
$font-size-root: 16px;
$font-size-base: 1rem;
$font-weight-base: 400;
$line-height-base: 1.8;

$headings-font-family: "Roboto", "Roboto-Fallback", sans-serif;
$headings-color: #142d6f;
$headings-sub-color: $body-color;
$headings-sub-font-weight: 700;
$headings-font-weight: 700;
$headings-line-height: 1.182em;
$headings-letter-spacing: 0;
$headings-text-transform: unset;

$btn-font-weight: 400;
$container-padding-x: 60px;
$border-radius: 1rem;

$btn-border-radius: 4rem;
$btn-border-radius-sm: $btn-border-radius;
$btn-border-radius-lg: $btn-border-radius;

$btn-padding-y: 0.5rem;
$btn-padding-x: 0.75rem;

$cta: #113d3c;
$primary: #01508a;
$secondary: #148ec9;
$primary-hover: darken($primary, 5%);
$default: #d6d6d6;
$success: #76b62e;
$info: #0dcaf0;
$warning: #ec6c20;
$danger: #dc3545;
$light: #f8f9fa;
$dark: #212529;

$primary-text: #324b4f;
$secondary-text: $gray-600 !default;
$success-text: $green-600 !default;
$info-text: $cyan-700 !default;
$warning-text: $yellow-700 !default;
$danger-text: $red-600 !default;
$light-text: $gray-600 !default;
$dark-text: $gray-700 !default;

$primary-bg-subtle: $blue-100 !default;
$secondary-bg-subtle: $gray-100 !default;
$success-bg-subtle: $green-100 !default;
$info-bg-subtle: $cyan-100 !default;
$warning-bg-subtle: $yellow-100 !default;
$danger-bg-subtle: $red-100 !default;
$light-bg-subtle: mix($gray-100, $white) !default;
$dark-bg-subtle: $gray-400 !default;

$primary-border-subtle: $blue-200 !default;
$secondary-border-subtle: $gray-200 !default;
$success-border-subtle: $green-200 !default;
$info-border-subtle: $cyan-200 !default;
$warning-border-subtle: $yellow-200 !default;
$danger-border-subtle: $red-200 !default;
$light-border-subtle: $gray-200 !default;
$dark-border-subtle: $gray-500 !default;

$link-color: $primary;
$link-hover-color: darken($primary, 10%);

//$border-color: #dee2e6;
$custom-theme-colors: map-merge($theme-colors,
    ("cta": $cta,
      "primary": $primary,
      "secondary": $secondary,
      "default": $default,
      "success": $success,
      "info": $info,
      "warning": $warning,
      "danger": $danger,
      "dark": $dark,
      "light": $light,
    ));
$pageheader-bg: $white;
$placeholder-bg: $body-bg;
$placeholder-bg-dark: #5ca5b7;
$boxshadow: 1px 22px 35px rgba(0, 0, 0, 0.1);

$header-bg: #fff;
$header-color: #000;

$header-logo-width: 160px;
$header-logo-width-sm: 160px;
$header-logo-width-md: 200px;
$header-logo-width-lg: 200px;
$header-logo-width-xl: 240px;

$swiper: $body-color;
$swiper-hover: #{lighten($swiper, 10%)};
$swiper-data-bg: #f7f5ed;
$swiper-data-color: $swiper;
$swiper-data-title-color: $swiper-data-color;
$swiper-data-subtitle-color: $swiper-data-color;

$swiper-scrollbar-drag-bg: $gray-400;
$swiper-scrollbar-bg: #{lighten($swiper-scrollbar-drag-bg, 10%)};
$header-navbar-toggle-color: rgb(0, 0, 0);
$header-navbar-toggle-color-hover: $primary;
$header-navbar-toggle-bordercolor: rgb(0, 0, 0);
$header-navbar-toggle-bordercolor-hover: $primary;
$header-navbar-toggle-bg: rgba(255, 255, 255, 1);
$header-navbar-toggle-bg-hover: $header-navbar-toggle-bg;
$header-navbar-toggle-bar-color: $header-navbar-toggle-color;
$header-navbar-toggle-bar-color-hover: rgb(255, 255, 255);

$offcanvas-bg-color: var(--hn-white);
$offcanvas-color: $body-color;
$offcanvas-border-color: transparent;

$mainmenu-color: $white;
$mainmenu-link-font-size: 1.313rem;
$mainmenu-link-font-weight: 500;
$mainmenu-link-color: white;
$mainmenu-link-hover-color: $primary;
$mainmenu-link-active-color: $primary;
$mainmenu-link-disabled-color: $default;

$mainmenu-postop-link-color: var(--hn-headings-color);
$mainmenu-postop-link-hover-color: $primary;
$mainmenu-postop-link-active-color: $primary;
$mainmenu-postop-link-disabled-color: $default;

$mainmenu-offcanvas-link-font-size: $font-size-base;
$mainmenu-offcanvas-link-font-weight: $font-weight-base;
$mainmenu-offcanvas-link-color: white;
$mainmenu-offcanvas-link-hover-color: dark(white, 10%);
$mainmenu-offcanvas-link-disabled-color: $default;

$main-slider-bg: transparent;
$main-slider-color: $body-color;
$main-slider-swiper-color: $main-slider-color;
$main-slider-title-color: $headings-color;
$main-slider-title-color-dark: $headings-color;
$main-slider-subtitle-color: var(--#{$prefix}headings-sub-color);
$main-slider-title-weight: $headings-font-weight;
$main-slider-subtitle-weight: $font-weight-base;
$main-slider-before-gradient: linear-gradient(to bottom,
    rgba(0, 0, 0, 0.6) 0%,
    rgba(255, 255, 255, 0) 100%);
$main-slider-after-gradient: linear-gradient(to top,
    $main-slider-bg 0%,
    rgba(255, 255, 255, 0) 100%);

$main-slider-scrollbar-drag-bg: #fff;
$main-slider-scrollbar-bg: rgba(255, 255, 255, 0.5);

$main-services-bg: $main-slider-bg;
$main-services-gradient: linear-gradient(to bottom,
    $main-slider-bg 0%,
    rgba(255, 255, 255, 0) 100%);
$main-services-title-color: $main-slider-title-color;
$main-services-subtitle-color: var(--#{$prefix}headings-sub-color);

$pageheader-color: var(--#{$prefix}white);
$pageheader-title-color: var(--#{$prefix}white);
$pageheader-subtitle-color: var(--#{$prefix}white);

//$pageheader-image-bg: var(--#{$prefix}pageheader-bg);
$pageheader-image-bg: $secondary;
$pageheader-image-color: var(--#{$prefix}pageheader-color);
$pageheader-image-title-color: var(--#{$prefix}pageheader-title-color);
$pageheader-image-title-weight: 700;
$pageheader-image-subtitle-color: var(--#{$prefix}pageheader-subtitle-color);
$pageheader-image-subtitle-weight: 700;
$pageheader-image-gradient: none;
$pageheader-image-before-gradient: $pageheader-image-gradient;
$pageheader-image-after-gradient: $pageheader-image-gradient;

$first-section-after-pageheader-bg: rgb(16, 57, 94);
$first-section-after-pageheader-gradient: $main-services-gradient;
$first-section-after-pageheader-color: var(--#{$prefix}body-color);
$first-section-after-pageheader-title: var(--#{$prefix}headings-color);
$first-section-after-pageheader-subtitle: var(--#{$prefix}headings-sub-color);

$company-form-bg: var(--#{$prefix}secondary-bg);

$footer-bg: var(--#{$prefix}body-bg-rgb);
/* $footer-bg: linear-gradient(
  to bottom,
  rgba(var(--#{$prefix}body-bg-rgb), 1) 0%,
  rgba(var(--#{$prefix}body-bg-rgb), 1) 100%
);*/

$footer-color: var(--#{$prefix}body-color);
$footer-header-color: var(--#{$prefix}headings-color);
$footer-icon-border: 1px solid $footer-color;
$footer-social-color: $footer-color;
$footer-social-border: 1px solid $footer-color;
$sub-footer-bg: $footer-bg;
$sub-footer-color: $footer-color;

$card-bg: var(--#{$prefix}swiper-data-bg);
$card-cap-bg: rgb(255 255 255 / 5%);

$nav-tabs-border-color: $border-color;
$nav-tabs-border-width: $border-width;
$nav-tabs-border-radius: $border-radius;
$nav-tabs-link-hover-border-color: $nav-tabs-border-color;
$nav-tabs-link-active-color: $white;
$nav-tabs-link-active-bg: $primary;
$nav-tabs-link-active-border-color: $nav-tabs-border-color;

$object-bg: var(--#{$prefix}swiper-data-bg);
$object-color: var(--hn-body-color);
$object-link-color: var(--#{$prefix}link-color);
$object-link-hover-color: var(--#{$prefix}link-hover-color);
$object-title-color: var(--#{$prefix}headings-color);
$object-salestitle-color: var(--#{$prefix}object-color);
$object-feautre-color: var(--#{$prefix}object-link-color);
$object-stats-color: var(--#{$prefix}object-link-color);
$object-border-color: var(--#{$prefix}border-color);
$object-border-radius: var(--#{$prefix}border-radius);
$object-top-bg: var(--hn-white);
$object-top-color: $object-color;
$object-special-bg: var(--hn-white);
$object-special-color: $object-color;

$object-price-color: $cta;

$pagination-padding-x: 0.75rem;
$pagination-padding-y: 0.375rem;
$pagination-font-size: 1rem;
$pagination-color: #{$body-color};
$pagination-bg: #{$pageheader-bg};
$pagination-border-width: 1px;
$pagination-border-color: #{$border-color};
$pagination-border-radius: 0.375rem;
$pagination-hover-color: #{$primary};
$pagination-hover-bg: #{$pageheader-bg};
$pagination-hover-border-color: #{$pagination-border-color};
$pagination-focus-color: #{$primary};
$pagination-focus-bg: #e9ecef;
$pagination-focus-box-shadow: 0 0 0 0.25rem, rgba(13, 110, 253, 0.25);
$pagination-active-color: #fff;
$pagination-active-bg: #{$primary};
$pagination-active-border-color: #{$primary};
$pagination-disabled-color: #6c757d;
$pagination-disabled-bg: #fff;
$pagination-disabled-border-color: #dee2e6;

$usp-card-item-title-color: $primary;
$usp-card-item-color: var(--#{$prefix}swiper-data-color);
$usp-card-item-bg: var(--#{$prefix}swiper-data-bg);
$usp-card-item-border-radius: var(#{$prefix}border-radius);

$accordion-padding-y: 1rem;
$accordion-padding-x: 1.25rem;
$accordion-color: var(--#{$prefix}swiper-data-color); // Sass variable because of $accordion-button-icon
$accordion-bg: var(--#{$prefix}swiper-data-bg);
$accordion-border-width: var(--#{$prefix}border-width);
$accordion-border-color: var(--#{$prefix}border-color);
$accordion-border-radius: var(--#{$prefix}border-radius);
$accordion-inner-border-radius: subtract($accordion-border-radius,
    $accordion-border-width);

$accordion-body-padding-y: $accordion-padding-y;
$accordion-body-padding-x: $accordion-padding-x;

$accordion-button-padding-y: $accordion-padding-y;
$accordion-button-padding-x: $accordion-padding-x;
$accordion-button-color: var(--#{$prefix}body-color);
$accordion-button-bg: var(--#{$prefix}accordion-bg);
$accordion-transition: $btn-transition, border-radius 0.15s ease;
$accordion-button-active-bg: var(--#{$prefix}primary-bg-subtle);
$accordion-button-active-color: $accordion-button-color;

$accordion-button-focus-border-color: $input-focus-border-color;
$accordion-button-focus-box-shadow: $btn-focus-box-shadow;

$accordion-icon-width: 1.25rem;
$accordion-icon-color: $body-color;
$accordion-icon-active-color: $primary-text;
$accordion-icon-transition: transform 0.2s ease-in-out;
$accordion-icon-transform: rotate(-180deg);

$accordion-button-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
$accordion-button-active-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-active-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");

/* DARK STYLES */
$cta-dark: $cta;
$primary-dark: $primary;
$secondary-dark: black;
$default-dark: #d6d6d6;
$success-dark: $success;
$info-dark: $info;
$warning-dark: $warning;
$danger-dark: $danger;

$primary-text-dark: #000;
$secondary-text-dark: $gray-300 !default;
$success-text-dark: $green-300 !default;
$info-text-dark: $cyan-300 !default;
$warning-text-dark: $yellow-300 !default;
$danger-text-dark: $red-300 !default;
$light-text-dark: $gray-100 !default;
$dark-text-dark: $gray-300 !default;

$primary-bg-subtle-dark: $blue-900 !default;
$secondary-bg-subtle-dark: $gray-900 !default;
$success-bg-subtle-dark: $green-900 !default;
$info-bg-subtle-dark: $cyan-900 !default;
$warning-bg-subtle-dark: $yellow-900 !default;
$danger-bg-subtle-dark: $red-900 !default;
$light-bg-subtle-dark: $gray-800 !default;
$dark-bg-subtle-dark: mix($gray-800, $black) !default;

$primary-border-subtle-dark: $blue-700 !default;
$secondary-border-subtle-dark: $gray-700 !default;
$success-border-subtle-dark: $green-700 !default;
$info-border-subtle-dark: $cyan-800 !default;
$warning-border-subtle-dark: $yellow-800 !default;
$danger-border-subtle-dark: $red-700 !default;
$light-border-subtle-dark: $gray-700 !default;
$dark-border-subtle-dark: $gray-800 !default;

$body-color-dark: #d5cccc;
$body-bg-dark: #180000;

$body-emphasis-color-dark: $gray-100;
$body-secondary-color-dark: rgba($body-color-dark, 0.75);
$body-secondary-bg-dark: $gray-800;
$body-tertiary-color-dark: rgba($body-color-dark, 0.5);
$body-tertiary-bg-dark: mix($gray-800, $gray-900, 50%);
$emphasis-color-dark: $white;
$border-color-dark: $gray-700;
$border-color-translucent-dark: rgba($white, 0.15);
$headings-color-dark: #fff;
$headings-sub-color-dark: $white;
$link-color-dark: $primary;
$link-hover-color-dark: $primary;
$code-color-dark: $pink-300;

$pageheader-bg-dark: #222222;

$swiper-dark: $body-color-dark;
$swiper-hover-dark: $primary;
$swiper-data-bg-dark: var(--#{$prefix}pageheader-bg);
$swiper-data-color-dark: var(--#{$prefix}swiper);
$swiper-data-title-color-dark: var(--#{$prefix}headings-color);
$swiper-data-subtitle-color-dark: var(--#{$prefix}swiper);
$swiper-scrollbar-bg-dark: rgba(255, 255, 255, 0.1);
$swiper-scrollbar-drag-bg-dark: var(--#{$prefix}swiper);

$header-bg-dark: #000;
$header-color-dark: #fff;

$header-navbar-toggle-color-dark: rgb(255, 255, 255);
$header-navbar-toggle-color-hover-dark: $primary;
$header-navbar-toggle-bordercolor-dark: rgb(0, 0, 0);
$header-navbar-toggle-bordercolor-hover-dark: $primary;
$header-navbar-toggle-bg-dark: rgba(0, 0, 0, 1);
$header-navbar-toggle-bg-hover-dark: rgba(0, 0, 0, 1);
$header-navbar-toggle-bar-color-dark: rgb(255, 255, 255);
$header-navbar-toggle-bar-color-hover-dark: rgb(255, 255, 255);

$offcanvas-bg-color-dark: var(--#{$prefix}body-bg);
$offcanvas-color-dark: $body-color-dark;
$offcanvas-border-color-dark: $border-color-dark;

$mainmenu-color-dark: $body-color-dark;
$mainmenu-link-color-dark: $body-color-dark;
$mainmenu-link-hover-color-dark: $primary;
$mainmenu-link-active-color-dark: $primary;
$mainmenu-link-disabled-color-dark: $default;

$mainmenu-postop-link-color-dark: #fff;
$mainmenu-postop-link-hover-color-dark: $primary;
$mainmenu-postop-link-active-color-dark: $primary;
$mainmenu-postop-link-disabled-color-dark: $default;

$mainmenu-offcanvas-link-color-dark: $body-color-dark;
$mainmenu-offcanvas-link-hover-color-dark: $primary;
$mainmenu-offcanvas-link-disabled-color-dark: $default;

$main-slider-bg-dark: transparent;
$main-slider-color-dark: var(--#{$prefix}headings-color);
$main-slider-before-gradient-dark: linear-gradient(to bottom,
    $main-slider-bg-dark 0%,
    rgba(255, 255, 255, 0) 100%);
$main-slider-after-gradient-dark: linear-gradient(to top,
    $main-slider-bg-dark 0%,
    rgba(255, 255, 255, 0) 100%);

$main-services-bg-dark: $main-slider-bg-dark;
$main-services-gradient-dark: linear-gradient(to bottom,
    $main-slider-bg-dark 0%,
    rgba(255, 255, 255, 0) 100%);

$first-section-after-pageheader-gradient-dark: $main-services-gradient-dark;
$company-form-bg-dark: #fff;

$footer-bg-dark: var(--#{$prefix}body-bg-rgb-dark);
/* $footer-bg: linear-gradient(
  to bottom,
  rgba(var(--#{$prefix}body-bg-rgb), 1) 0%,
  rgba(var(--#{$prefix}body-bg-rgb), 1) 100%
);*/

$footer-color-dark: $body-color-dark;
$footer-header-color-dark: $headings-color-dark;
$footer-icon-border-dark: 1px solid $footer-color-dark;
$footer-social-color-dark: $footer-color-dark;
$footer-social-border-dark: 1px solid $footer-color-dark;
$sub-footer-bg-dark: $footer-bg-dark;
$sub-footer-color-dark: $footer-color-dark;

$card-bg-dark: $pageheader-bg-dark;
$card-cap-bg-dark: rgb(255 255 255 / 5%);

$nav-tabs-border-color-dark: $border-color-dark;
$nav-tabs-link-hover-border-color-dark: $nav-tabs-border-color-dark;
$nav-tabs-link-active-color-dark: $white;
$nav-tabs-link-active-bg-dark: $primary;
$nav-tabs-link-active-border-color-dark: $nav-tabs-border-color-dark;

$object-bg-dark: $secondary;
$object-color-dark: $body-color-dark;
$object-link-color-dark: var(--#{$prefix}link-color);
$object-link-hover-color-dark: var(--#{$prefix}link-hover-color);
$object-title-color-dark: var(--#{$prefix}headings-color);
$object-salestitle-color-dark: var(--#{$prefix}object-color);
$object-feautre-color-dark: var(--#{$prefix}object-link-color);
$object-stats-color-dark: var(--#{$prefix}object-link-color);
$object-border-color-dark: var(--#{$prefix}border-color);
$object-top-bg-dark: $secondary;
$object-top-bg-rgb-dark: 15, 37, 46;
$object-top-color-dark: $object-color-dark;
$object-special-bg-dark: $secondary;
$object-special-bg-rgb-dark: rgb(88, 64, 18);
$object-special-color-dark: $object-color-dark;
$object-price-color-dark: var(--#{$prefix}body-color);

$pagination-color-dark: var(--#{$prefix}body-color);
$pagination-bg-dark: var(--#{$prefix}pageheader-bg);
$pagination-border-color-dark: var(--#{$prefix}border-color);
$pagination-hover-color-dark: var(--#{$prefix}primary);
$pagination-hover-bg-dark: var(--#{$prefix}pageheader-bg);
$pagination-hover-border-color-dark: var(--#{$prefix}border-color);
$pagination-focus-color-dark: var(--#{$prefix}primary);
$pagination-focus-bg-dark: #e9ecef;
$pagination-focus-box-shadow-dark: 0 0 0 0.25rem, rgba(13, 110, 253, 0.25);
$pagination-active-color-dark: #fff;
$pagination-active-bg-dark: var(--#{$prefix}primary);
$pagination-active-border-color-dark: var(--#{$prefix}primary);
$pagination-disabled-color-dark: #6c757d;
$pagination-disabled-bg-dark: #fff;
$pagination-disabled-border-color-dark: #dee2e6;

$usp-card-item-title-color-dark: var(--#{$prefix}primary);
$usp-card-item-color-dark: var(--#{$prefix}swiper-data-color);
$usp-card-item-bg-dark: var(--#{$prefix}swiper-data-bg);

@import "../../THEMES/HW044/scss/required-footer";

@import "layout/global";
@import "layout/footer";
@import "layout/menu";
@import "layout/home";
@import "layout/order";
@import "layout/page";
@import "layout/objects";
@import "layout/type";