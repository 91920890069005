//TYPE

p {
  @include media-breakpoint-down(sm) {
    hyphens: auto;
  }

  &.lead {
    font-size: 1.125rem;
    font-weight: var(--hn-body-font-weight);
  }
}

h1,
h2,
h3,
h4,
h5,
.h1,
.h2,
.h3,
.h4,
.h5 {
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0;
  line-height: 1.4;

  &.quote {
    text-transform: none;
  }

  span {
    display: inline-block;

    &.bold {
      font-weight: bold;
    }
  }


  &.lowercase {
    font-family: var(--hn-body-font-family);
    text-transform: unset;
    font-weight: 300;
  }
}

h1,
h1.section__title,
.h1 {
  @include font-size(3rem);

  &.subtitle,
  .subtitle {
    font-family: var(--hn-body-font-family);
    display: inline;
    font-size: .9rem;
    margin-right: 1.5rem;
  }
}

.subtitle {
  font-family: var(--hn-body-font-family);
  display: inline;
  font-size: .825rem;
  letter-spacing: 2px;
}

h2,
.h2 {
  @include font-size(2rem);

  &.subtitle {
    font-size: .9rem;
  }
}

h3,
.h3 {
  @include font-size(1.5rem);
}

p {
  .subtitle {
    margin-right: 3rem;
  }

  &.border-top {
    padding-top: 1rem;
  }

  strong {
    font-family: var(--hn-headings-font-family)
  }
}