.textpage {
  --hn-first-section-after-pageheader-subtitle: var(--hn-white);

  .section {
    &.pageheader {
      background-color: var(--hn-white);

      &:not(.large) {
        background-color: var(--hn-gray-100);
        border-radius: 1rem;
        margin-left: calc(var(--hn-gutter-x) * 0.5);
        margin-right: calc(var(--hn-gutter-x) * 0.5);
        padding: calc(1.5rem + 1.5vw) 0;
      }

      &.large {
        height: auto;

        @include media-breakpoint-up(lg) {
          height: 50vh;
          min-height: 450px;
        }

        &.hasImage {
          margin: 0 calc(var(--hn-gutter-x) * 0.5);
          margin-bottom: calc(1.5rem + 1.5vw);

          &:before,
          &:after {
            display: none;
          }

          .image {
            width: 100%;
            height: 100%;
            border-radius: var(--hn-border-radius);
            overflow: hidden;
            position: absolute;

            &:before {
              content: "";
              width: 100%;
              height: 100%;
              background: rgba(0, 36, 62, 0.35);
            }

            .object-fit {
              object-position: bottom;
            }
          }

          >.container {
            height: 100%;
          }

          .text {
            flex-direction: column;
            height: inherit;

            .title {
              @include font-size(2.5rem);
            }

            h1,
            h2 {
              color: var(--hn-white);
            }

            .btn-row {
              margin-top: 2rem;

              @include media-breakpoint-up(lg) {
                margin-top: auto;
              }
            }
          }
        }
      }
    }

    &.textblock2 {
      .text {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        background: var(--hn-primary);
        border-radius: 1rem;
        padding: 1rem;

        @include media-breakpoint-up(lg) {
          flex-wrap: nowrap;
        }

        h3 {
          @include font-size(2rem);
          margin: calc(2rem + 4vw);
          align-self: center;
          order: 2;
          text-align: center;
          color: var(--hn-white);

          @include media-breakpoint-up(lg) {
            text-align: left;
          }
        }

        .h3 {
          color: var(--hn-white);
        }
      }

      &.textblock5 {
        background: var(--hn-gray-100);
      }
    }
  }
}

.spacer {
  &_textblock {
    width: 100%;
    height: 6vw;

    &-sm {
      height: 3vw;
    }
  }
}

.company {
  .section {
    &.pageheader {
      &.large {
        height: auto;
        background-color: var(--hn-body-bg);
        padding-top: 6rem;

        @include media-breakpoint-up(lg) {
          padding-top: 1rem;
          padding-bottom: unset;
        }

        >.container {
          max-width: 66rem;

          .text {
            padding-top: unset;

            .subtitle {
              display: none;
            }

            h1 {
              @include font-size(2.75rem);
            }
          }
        }
      }
    }

    &.contact {
      padding: unset;
    }
  }

  >.container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: unset;
    padding: unset;
    align-items: stretch;
    justify-content: center;

    @include media-breakpoint-up(lg) {
      flex-wrap: nowrap;
    }
  }

  &__details {
    order: 1;
    position: relative;
    margin: unset;
    padding: unset;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .text {
      margin: unset;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;

      img {
        object-fit: cover;
        height: 100%;
      }
    }

    &-item {
      position: relative;
      z-index: 9;
      margin-bottom: unset;
      margin-left: auto;
      min-width: 20rem;
      padding-left: 1rem;

      &.company__email {
        padding-bottom: 1rem;
      }
    }
  }

  &__form {
    padding: unset;
    background: none;
    box-shadow: unset;
    border-radius: unset;
    order: 2;
    width: 100%;
    padding: 30px;
    background: var(--hn-gray-100);
    border-radius: 1rem;

    @include media-breakpoint-up(lg) {
      margin: auto;
      padding: 4rem;
    }

    >label {
      font-size: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    .comments {
      .form-floating>label {
        align-items: flex-start;
      }
    }

    .form-control {
      border-radius: unset;
      background: unset;
      border: unset;
      border-bottom: 1px solid var(--hn-primary);
      color: color-mix(in srgb, var(--hn-white), var(--hn-primary) 75%);

      &:focus {
        box-shadow: unset;
        border-bottom: 1px solid #beb3a7;
      }
    }

    .control-label {
      color: color-mix(in srgb, var(--hn-white), var(--hn-primary) 75%);
    }

    .input-group-text {
      background-color: unset;
      border: unset;
      border-bottom: 1px solid var(--hn-primary);
      color: var(--hn-primary);
      border-radius: unset;
    }

    .form-check-label {
      letter-spacing: 0.5px;
    }

    .form-floating>.form-control-plaintext~label,
    .form-floating>.form-control:focus~label,
    .form-floating>.form-control:not(:placeholder-shown)~label,
    .form-floating>.form-select~label {
      transform: scale(0.65) translateY(-1.5rem) translateX(0.15rem);
    }

    .btn-primary {
      --hn-btn-color: var(--hn-white);
      --hn-btn-bg: var(--hn-primary);
    }
  }

  &__finance {
    margin-bottom: unset;
  }
}

.member {
  background-color: var(--hn-primary-bg);

  .pageheader {
    padding-bottom: unset;
    background: var(--hn-primary-bg);
  }

  .container {
    max-width: 66rem;
  }

  .main {
    padding-top: unset;
    padding-bottom: 2rem;
  }

  .loginorsubscribe {
    >.container>.row {
      flex-wrap: wrap;
      row-gap: 2rem;

      >* {
        width: 100%;
        flex: 1 0 auto;
      }
    }
  }
}

.employee {
  &__function {
    color: var(--hn-primary);
    font-size: var(--step-0);
    display: block;
  }
}

body:not(.home, .page) {
  .recentlyviewed {
    display: none;
  }
}

.page28,
.page63 {
  .section {
    &.textblock3 {
      background: var(--hn-gray-100);
      @include custom-padding(top);
    }

    &.textblock4 {
      @include custom-padding(top);
    }
  }
}

.page80 {
  .section {
    &.textblock3 {
      padding-bottom: unset;
    }

    &.textblock7 {
      padding-top: unset;

      .text {
        text-align: center;
      }
    }
  }
}

.page67,
.page57 {
  .section {
    &.textblock3 {
      background: var(--hn-secondary);
    }
  }
}

.page56 {
  .section {
    &.textblock5 {
      background: var(--hn-secondary);
      @include custom-padding(top);
    }

    &.textblock6 {
      @include custom-padding(top);
      background: Var(--hn-gray-100);
    }

    &.textblock7 {
      @include custom-padding(top);

      .text {
        text-align: center;
      }
    }
  }
}

.logo_swiper {
  max-width: 100%;
  overflow: hidden;
  max-height: 15rem;

  .swiper-wrapper {
    align-items: center;
    margin-bottom: 3rem;
    margin-top: 3rem;

    .swiper-slide {
      display: flex;
      justify-content: center;
    }

    img {
      max-width: 8rem;
      object-fit: contain;
    }
  }
}

.employees {
  .section {
    &.pageheader {
      background-color: var(--hn-gray-100);
      border-radius: 1rem;
      margin-left: calc(var(--hn-gutter-x) * 0.5);
      margin-right: calc(var(--hn-gutter-x) * 0.5);
      padding: calc(1.5rem + 1.5vw) 0;
    }

    &.main {
      padding: 3rem 0;
    }

    &.employees {
      padding-top: unset;

      .employees {
        &__roles {
          display: none;
        }
      }
    }

    &.specials {
      order: 9;
    }
  }
}

.page {
  ul li {
    text-align: left;
  }
}

.page78,
.page62,
.page63,
.page35 {
  .pageheader {
    .image {
      img {
        object-position: bottom;
      }
    }
  }
}

.page62 {
  .section {

    &.textblock5,
    &.textblock4 {
      background-color: var(--hn-gray-100);
    }

    &.textblock4,
    &.textblock6 {
      @include custom-padding(top);
    }
  }
}

.page69,
.page71,
.page73 {
  .header {

    &__topmenu,
    &__langpicker {
      display: none;
    }
  }

  .section {
    &.pageheader {
      &.hasImage {
        display: none;

      }
    }

    &.main {
      &.hasImage {
        height: auto;
        margin: 0 calc(var(--hn-gutter-x) * 0.5);
        margin-bottom: calc(1.5rem + 1.5vw);
        position: relative;

        @include media-breakpoint-up(lg) {
          height: 50vh;
          min-height: 400px;
        }

        >.container {
          @include make-max-widths-container-width;
          position: unset;
          height: 100%;
        }

        .image:not(img) {
          position: absolute;
          height: 100%;
          margin-top: unset;

          >img {
            object-position: bottom;
          }

        }

        .text {
          position: relative;
          z-index: 9;
          max-width: 600px;
          display: flex;
          flex-direction: column;
          align-self: flex-start;
          margin-top: 2rem;
          flex-grow: 1;
          padding-right: unset;

          >.btn-row {
            margin-top: auto;
            padding-top: 1rem;
          }

          h1 {
            color: var(--hn-white);
            max-width: 70rem;
          }

          h2 {
            color: var(--hn-white);
          }
        }
      }
    }
  }
}